import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import logo from './logo.png';
import './App.css';

function Image(props) {
  return (
    <img className={props.className} src={props.src} alt={props.alt} />
  );
}

function NavButton(props) {
  return (
    <button title={props.name} key={props.ourkey} className={props.className} onClick={props.onClick}>{props.name}</button>
  );
}

class ConnectLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: {
        linkedin: {
          image: '/in.png',
          href: 'https://www.linkedin.com/in/howard-ablat',
        },
        facebook: {
          image: '/fb.png',
          href: 'https://www.facebook.com/len.zelpher',
        },
        email: {
          image: '/email.png',
          href: 'mailto:howardablat@gmail.com',
        }
      }
    };
  }
  render() {
    return (
      <a className="connect-link" href={this.state.icons[this.props.value].href} alt={"link to " + this.props.value}>
        <ConnectIcon image={this.state.icons[this.props.value].image} alt={"link to " + this.props.value} />
      </a>
    );
  }
}

function ConnectIcon(props) {
  return (
    <img className="connect-icon" src={props.image} alt={props.alt} />
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      hero: App.CONTENT[0].display[0].hero,
      html: App.CONTENT[0].display[0].html,
    };
  }
  renderNav(item, i) {
    return(
      <NavButton
        ourkey={"nav" + i}
        key={"nav" + i}
        className={'nav-button' + (this.state.selected === i ? ' selected' : '')}
        name={item.label}
        onClick={() => this.handleButtonClick(item, i)}
      />
    );
  }

  handleButtonClick(item, i) {
    const selected = i;
    const hero = item.display[0].hero;
    const html = item.display[0].html;
    this.setState({
      selected: selected,
      hero: hero,
      html: html,
    });
    // Check if were on responsive mode and navigation bars are present.
    let x = document.getElementById("nav-icon");
    if (x.offsetWidth > 0 && x.offsetHeight > 0) {
      // And hide it.
      document.getElementById("text-navs").className = "text-navs";
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <button className="header-logo" onClick={() => this.handleButtonClick(App.CONTENT[0], 0)}>
            <Image className="header-logo-img my-picture" src="/header-logo.png" alt="howard's picture" />
            <span className="header-logo-txt">
              <Image className="header-logo-img logo-img" src={logo} alt="logo picture" />
              oward Ablat
            </span>
          </button>
          <div className="navigation">
            <div className="text-navs" id="text-navs">
              {App.CONTENT.map((content, i) => this.renderNav(content, i))}
            </div>
            <button
              className="nav-icon"
              id="nav-icon"
              onClick={() => {
                let x = document.getElementById("text-navs");
                if (x.classList.contains("responsive")) {
                  x.classList.remove("responsive");
                } else {
                  x.classList.add("responsive");
                }
              }}
            >
              <i className="fa fa-bars" />
            </button>
          </div>
        </header>
        <main className="main-content" style={{backgroundImage: "url(" + this.state.hero + ")"}}>
          <div className="body-text">
            <ReactCSSTransitionGroup transitionName="body-fade" transitionEnterTimeout={600} transitionLeave={false}>
              {this.state.html}
            </ReactCSSTransitionGroup>
          </div>
        </main>
        <footer className="app-footer">
          <div className="connect">
            <h2>Connect with me</h2>
            <div className="connect-links">
              <ConnectLink value="linkedin" />
              <ConnectLink value="facebook" />
              <ConnectLink value="email" />
            </div>
          </div>
          <div className="resume">
            <h2>Resume</h2>
            <a href="/HowardAblat-Resume-2019.pdf" alt="Link to my resume">
              <img className="resume-img" src="/pdf.png" alt="download my resume" />
            </a>
          </div>
        </footer>
      </div>
    );
  }

  static get CONTENT() {
    return [
      {
        label: 'About',
        display: [
          {
            hero: '/hero/code.png',
            html: (
              <article key="about">
                <h1>About Me</h1>
                <h2>Profesional Summary</h2>
                <p>
                  Acquia-certified Drupal 8 developer with extensive experience in custom module development, troubleshooting, site building, and release management. Highly skilled at software development under strict time schedules and has strong knowledge of functional analysis and specification, design and documentation, object-oriented programming, and 508 Compliance. Also designs, implements, and manages system architectures and web development and hosting infrastructures built on virtual, and cloud computing technologies including AWS and VMWare.
                </p>
                <h2>Hobbies and Interests</h2>
                <p>
                  Now that's out of the way. Let me tell you more about what I like to do outside of work.
                  I love hiking, camping, fishing, and anything that involves being outdoors and enjoying nature.
                  I'm also a big Anime fan! Its hard to list my favorite shows, as there's so many good ones, but the few that come to mind
                  are Made in Abyss, Saekano, Mushishi, Natsume, Non-non Biyori...honestly this can go on for a while so I'll stop there.
                  I also love playing video games, although I haven't had much time to play as I want to&nbsp;
                  <i className="fa fa-frown-o" aria-hidden="true" />.
                  Favorite games are mostly RPGs like Fire Emblem, Final Fantasy, Pokemon, Elder Scrolls, and Monster Hunter series.
                  Last, but not least, I love to code/program, and if I don't do it for a while I get withdrawal symptoms.
                  Hence the reason I'm a developer is to satiate my coding addiction&nbsp;
                  <i className="fa fa-smile-o" aria-hidden="true" />.
                </p>
              </article>
            ),
          },
        ],
      },
      {
        label: 'Portfolio',
        display: [
          {
            hero: '/hero/portfolio.png',
            html: (
              <article key="portfolio" className="portfolio-content">
                <h1>Recent Projects</h1>
                <div className="portfolio-texts">
                  <div className="portfolio-entry">
                    <a href="https://go4life.nia.nih.gov" target="_blank" rel="noopener noreferrer">
                      <h2>Go4Life (2018)</h2>
                    </a>
                    <p>
                      <img className="portfolio-img" src="/portfolio/go4life.jpg" alt="Go4Life Website screenshots on different devices" />
                      <span>
                        Lead developer for migration of campaign site from Drupal 7 to WordPress.
                        Developed a plugin to create custom post types, with custom ACF fields, that replicate Drupal content types.
                        Developed an automatic and also curated post relation plugin based on taxonomy and tags.
                        Built page templates, in accordance to wireframes, and worked with front-end developers to realize page designs.
                      </span>
                    </p>
                  </div>
                  <div className="portfolio-entry">
                    <a href="https://blsa.nia.nih.gov" target="_blank" rel="noopener noreferrer">
                      <h2>Baltimore Longitudinal Study on Aging “Data Use” Application (2018)</h2>
                    </a>
                    <p>
                      <img className="portfolio-img" src="/portfolio/blsa.jpg" alt="BLSA Website screenshots on different devices" />
                      <span>
                        Backend developer and server administrator for redesign of site from Drupal 7 to Drupal 8.
                        Worked with lead developer to create migration scripts for data migration from Drupal 7 to Drupal 8.
                        Configured web server and services, using CloudFormation scripts, on AWS (CloudFront, ELB, RDS, EFS, and Auto scaling group) for development, staging, and production environment.
                        Developed automated deployment jobs of both code and configuration to the different site environments.
                      </span>
                    </p>
                  </div>
                  <div className="portfolio-entry">
                    <a href="https://www.nia.nih.gov" target="_blank" rel="noopener noreferrer">
                      <h2>National Institute on Aging Web site (2016-2017)</h2>
                    </a>
                    <p>
                      <img className="portfolio-img" src="/portfolio/nia.jpg" alt="NIA Website screenshots on different devices" />
                      <span>
                        Lead developer and site builder for redesign and migration from Drupal 7 to Drupal 8.
                        Worked with other developers to build custom migration scripts for data migration from Drupal 7 to Drupal 8.
                        Developed custom module for automated import of NED data from NIH remote web service to the site's staff listing directory.
                        Designed and built feature pages using TWIG templating and created dynamic views and blocks using Drupal 8 configuration management system.
                        Served as team lead during development sprints and organized and executed releases on staging and production environments.
                      </span>
                    </p>
                  </div>
                </div>
              </article>
            ),
          },
        ],
      },
      {
        label: 'Contact',
        display: [
          {
            hero: '/hero/contact.png',
            html: (
              <article key="contact" className="contact-content">
                <h1>Get In Touch!</h1>
                <div className="contact-text">
                  <div className="contact-email">
                    <h2>By Email</h2>
                    <a href="mailto:howardablat@gmail.com" alt="My email link">
                      HOWARDABLAT@GMAIL.COM
                      &nbsp;
                      <i className="fa fa-envelope" />
                    </a>
                  </div>
                  <div className="contact-phone">
                    <h2>By Phone</h2>
                    <a href="tel:3017926860">
                      (301) 792-6860
                      &nbsp;
                      <i className="fa fa-mobile" />
                    </a>
                  </div>
                </div>
                <p>
                  Feel free to reach out to me by phone. If I do not answer your call right away, please leave a message and I will get back to you.
                  Also keep in mind that your email, depending on your sender reputation, can go straight to my spam folder in which case I will not see it.
                </p>
              </article>
            ),
          },
        ]
      },
    ];
  }
}

export default App;
